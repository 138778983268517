import {
  SubscriptionActions,
  ActionsTypes,
  SubscriptionState,
} from '../../types/subscription';

const initialState = null;

const subscriptionReducer = (
  state = initialState,
  action: SubscriptionActions,
): SubscriptionState => {
  switch (action.type) {
    case ActionsTypes.SET_SUBSCRIPTION: {
      return action.payload ?? null;
    }

    default:
      return state;
  }
};

export default subscriptionReducer;
