import React from 'react';
import {
  Menu, Dropdown, Avatar, Space,
} from 'antd';
import { Link, useHistory } from 'react-router-dom';

import {
  SettingOutlined,
  FileTextOutlined,
  LogoutOutlined,
  DownOutlined,
  ApartmentOutlined,
  TeamOutlined,
  TableOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';

import { UserTypes } from 'src/constants/enums';

import routes from 'src/routes';

import useDecodedToken from 'src/hooks/useDecodedToken';

import { selectIsCustomerType } from 'src/store/userProfile/selectors';
import useTypedSelector from '../../../hooks/useTypedSelector';
import Icon from '../../util-components/Icon';
import './NavProfile.css';

function NavProfile() {
  const history = useHistory();
  const user = useTypedSelector((state: { userProfile: any; }) => state.userProfile);
  const isCustomerType = useTypedSelector(selectIsCustomerType);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { is_admin } = useDecodedToken();

  const menuItem = [
    {
      title: user.isProvider ? 'Мои предложения' : 'Мои заявки',
      icon: FileTextOutlined,
      path: routes.personal,
      condition: true,
    },
    {
      title: 'Витрина',
      icon: TableOutlined,
      path: user.isProvider
        ? routes.personalMarket.index
        : routes.market,
      condition: true,
    },
    {
      title: 'Модерация товаров',
      icon: TableOutlined,
      path: routes.marketModeration,
      condition: is_admin,
    },
    {
      title: 'Филиалы',
      icon: ApartmentOutlined,
      path: routes.branches.index,
      condition: UserTypes.Customer === user.type,
    },
    {
      title: 'История покупок',
      icon: ApartmentOutlined,
      path: routes.purchaseHistory,
      condition: isCustomerType,
    },
    {
      title: 'Сотрудники',
      icon: TeamOutlined,
      path: routes.employees.index,
      condition: UserTypes.Provider === user.type,
    },
    {
      title: 'Подписка',
      icon: ShoppingCartOutlined,
      path: routes.subscription.index,
      condition: isCustomerType,
    },
    {
      title: 'Настройки',
      icon: SettingOutlined,
      path: routes.settings,
      condition: true,
    },
  ];
  const profileImg = '/img/avatars/thumb-1.jpg';
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar
            style={{ background: '#918aff' }}
            size={55}
            src={profileImg}
          >
            {user.name ? user.name[0].toUpperCase() : ''}
          </Avatar>
          <div className="pl-3">
            <h4 className="mb-0">{user.name ? user.name : ''}</h4>
            <span className="text-muted">{user.isProvider ? 'Поставщик' : 'Заказчик'}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem
            .filter(({ condition }) => condition)
            .map((el) => (
              <Menu.Item key={el.title}>
                <Link to={el.path}>
                  <Icon className="mr-3" type={el.icon as any} />
                  <span className="font-weight-normal">{el.title}</span>
                </Link>
              </Menu.Item>
            ))}
          <Menu.Item
            key={menuItem.length + 1}
            onClick={() => {
              history.push(routes.logout);
            }}
          >
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Выход</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown arrow placement="bottomRight" overlay={profileMenu} trigger={['click']}>
      <Space>
        <span className="user-name-dropdown">{user.name ? user.name : ''}</span>
        <Avatar
          style={{ background: '#918aff' }}
          size={40}
          src={profileImg}
        >
          {user.name[0] ? user.name[0].toUpperCase() : ''}
        </Avatar>
        <DownOutlined className="navArrow" />
      </Space>
    </Dropdown>
  );
}

export default NavProfile;
