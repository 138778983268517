import {
  UserPermissionsState,
  UserPermissionsActions,
  ActionsTypes,
} from '../../types/user-permissions';

const initialState: UserPermissionsState = [];

const userPermissionsReducer = (
  state = initialState,
  action: UserPermissionsActions,
): UserPermissionsState => {
  switch (action.type) {
    case ActionsTypes.SET_USER_PERMISSIONS: {
      return action.payload;
    }

    default:
      return state;
  }
};

export default userPermissionsReducer;
