/* eslint-disable import/prefer-default-export */
import { usersApi } from 'src/api/api';
import { AxiosResponse } from 'axios';
import { message } from 'antd';
import { Dispatch } from 'react';
import {
  SetSubscription,
  ActionsTypes,
  ISubscription,
} from '../../types/subscription';

export const setSubscription = (data: ISubscription): SetSubscription => ({
  type: ActionsTypes.SET_SUBSCRIPTION,
  payload: data,
});

type GetSubscriptionResponse = AxiosResponse<{
  result: boolean
  msg: string;
  subscription: ISubscription
}>;

export const getSubscription = () => (dispatch: Dispatch<any>) => {
  usersApi.getSubscription()
    .then((res: GetSubscriptionResponse) => {
      if (res.status !== 200) {
        message.error('Произошла ошибка при получении данных о подписке. Обновите страницу');
        return;
      }

      dispatch(setSubscription(res.data.subscription));
    });
};
