import React, { useState } from 'react';
import {
  Button, message, Space, Typography,
} from 'antd';
import { Link, NavLink } from 'react-router-dom';
import { ContainerOutlined, SettingOutlined } from '@ant-design/icons';

import routes from 'src/routes';

import useTypedSelector from 'src/hooks/useTypedSelector';

import { selectIsCustomer, selectIsCustomerType } from 'src/store/userProfile/selectors';
import { usersApi } from 'src/api/api';
import { selectSubscription } from 'src/store/subscription/selectors';
import useActions from 'src/hooks/useActions';
import s from './styles.module.css';

const Subscription = () => {
  const { getSubscription } = useActions();
  const isCustomerType = useTypedSelector(selectIsCustomerType);
  const isCustomer = useTypedSelector(selectIsCustomer);

  const subscription = useTypedSelector(selectSubscription);

  const [isCreatingSubscription, setIsCreatingSubscription] = useState(false);
  const [isCancelingSubscription, setIsCancelingSubscription] = useState(false);

  const handleCancelSubscription = async () => {
    try {
      setIsCancelingSubscription(true);

      await usersApi.cancelSubscription();

      getSubscription();
    } catch (ex) {
      message.error('Произошла ошибка при отмене подписки. Попробуйте еще раз');
    } finally {
      setIsCancelingSubscription(false);
    }
  };

  const handleCreateSubscription = async () => {
    try {
      setIsCreatingSubscription(true);

      const { data } = await usersApi.createSubscription({
        returnUrl: window.location.origin + routes.subscription.index,
      });

      if (data.result === false) {
        message.error('У аккаунта уже есть активная подписка. Обновите страницу');

        return;
      }

      window.location.href = data.confirmationUrl;
    } catch (ex) {
      message.error('Произошла ошибка при оформлении подписки. Попробуйте еще раз');
      setIsCreatingSubscription(false);
    }
  };

  return (
    <div className={s.subscription}>
      <div className={s.links}>
        <Link to={routes.personal}>
          <Button
            type="default"
            icon={<ContainerOutlined />}
            size="large"
          >
            {isCustomerType ? 'Мои заявки' : 'Мои предложения'}
          </Button>
        </Link>
        <Link to={routes.settings}>
          <Button
            type="default"
            icon={<SettingOutlined />}
            size="large"
          >
            Настройки
          </Button>
        </Link>
      </div>
      <Typography.Title>
        Оплата подписки
      </Typography.Title>
      <Space size={10} direction="vertical">
        {subscription != null && (
          <div className={s.badge}>
            {subscription.type === 'demo' ? 'ДЕМОСТРАЦИОННЫЙ ПЕРИОД' : 'ПОДПИСКА АКТИВНА'}
            {' '}
            - полный функционал доступен
          </div>
        )}
        {subscription != null && subscription.type === 'paid' && subscription.status === 'activated' && (
          <>
            <Typography.Paragraph>
              Следующий платеж:
              {' '}
              {new Intl.DateTimeFormat('ru', { dateStyle: 'medium' }).format(new Date(subscription.nextPaymentDate))}
              , 1500 руб.
            </Typography.Paragraph>
            {isCustomer && (
              <Button
                className={s.cancelSubscriptionButton}
                onClick={handleCancelSubscription}
                loading={isCancelingSubscription}
              >
                Отменить подписку
              </Button>
            )}
          </>
        )}
        {subscription != null && subscription.type === 'paid' && subscription.status === 'canceled' && (
          <Typography.Paragraph strong>
            Подписка была отменена. Если захотите продолжить пользоваться сервисом,
            оформите подписку
            {' '}
            {new Date(subscription.nextPaymentDate).toLocaleDateString()}
          </Typography.Paragraph>
        )}
        {(subscription == null || subscription.type === 'demo') && (
          <>
            <Typography.Paragraph>
              Автоматическая абонентская плата: 1500 руб. в месяц
            </Typography.Paragraph>
            {isCustomer ? (
              <Button
                className={s.paySubscriptionButton}
                onClick={handleCreateSubscription}
                loading={isCreatingSubscription}
              >
                Оформить доступ
              </Button>
            ) : (
              <Typography.Paragraph strong>
                Для оформления доступа обратитесь к администратору аккаунта
              </Typography.Paragraph>
            )}
          </>
        )}
        <Typography.Title level={5}>
          Какие ограничения будут после окончания 30 дней?
        </Typography.Title>
        <Typography.Paragraph>
          Первые 30 дня со дня регистрации, весь функционал доступен бесплатно для тестирования
          и внедрения программы в бизнес процесс закупок, далее по подписке, если не получается
          продлить подписку или не хотите оформлять подписку после тестирования,
          то аккаунт сохраняется, но функционал ограничивается только просмотром активных/архивных
          заявок, чтобы пользователи могли видеть историю.
        </Typography.Paragraph>
        <Typography.Title level={5}>
          Порядок абонентской платы и процедура отмены
        </Typography.Title>
        <Typography.Paragraph>
          Подписка оформляется в личном кабинете пользователя. По факту оформления вы соглашаетесь
          с автоматической абонентской платой, продлеваемой каждый месяц без оповещения,
          пока не будет произведена отмена.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Оплата с карты, для выставления счёта пишите на почту
          {' '}
          <a className={s.link} href="mailto:hello@dentmp.ru" target="_blank" rel="noreferrer">hello@dentmp.ru</a>
        </Typography.Paragraph>
        <Typography.Paragraph>
          Подписку можно отменить и возобновить в любой момент в личном кабинете.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Если на момент отмены подписки оплаченный срок еще не закончился, доступ будет открыт
          ДО момента его окончания, а вместо последующего списания доступ закроется
          и оплаты сниматься больше не будут.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Если у вас не хватает средств на счете, оплата сниматься не будет,
          а доступ закроется в связи с неуплатой.
        </Typography.Paragraph>
        <NavLink className={s.link} to={routes.agreement}>
          Пользовательское соглашение
        </NavLink>
      </Space>
    </div>
  );
};

export default Subscription;
