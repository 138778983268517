/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-children-prop */
import React, {
  ChangeEvent, useCallback, useEffect, useRef, useState,
} from 'react';
import { nanoid } from 'nanoid';
import {
  NavLink, Redirect, useParams,
} from 'react-router-dom';
import {
  Input, message, Tooltip, Menu, Dropdown, AutoComplete,
} from 'antd';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import CurrencyInput from 'react-currency-input-field';
import ConfirmProposal from 'src/components/shared-components/Modals/ConfirmProposal';
import withHint from 'src/hocs/withHint';
import routes from 'src/routes';
import useDebounce from 'src/hooks/useDebounce';
import {
  InfoCircleOutlined, LeftOutlined, RightOutlined, SettingOutlined,
} from '@ant-design/icons';
import CustomerProposalContacts from 'src/components/CustomerProposalContacts';
import { LoadingButton } from 'src/components/shared-components/Loading';
import LoaderCentered from 'src/components/shared-components/Loader/LoaderCentered';
import { UserTypes } from 'src/constants/enums';
import { PermissionsGuard } from 'src/components/PermissionsGuard';
import { About } from '../../components';
import plusImg from '../../assets/img/plus.png';
import s from '../CustomerProposal/CustomerProposal.module.css';
import getCookie from '../../helpers/getCookie';
import useTypedSelector from '../../hooks/useTypedSelector';
import {
  IProduct, ProductItemProps, IProductFromBackend, ISearchResult, IAutoComplete,
} from '../CustomerProposal/types';
import { usersApi } from '../../api/api';

const CustomAutoComplete = withHint<IAutoComplete>(AutoComplete);

const ProductItem: React.FC<ProductItemProps> = ({
  product,
  deleteProduct,
  setProducts,
  products,
}) => {
  const [name, setName] = useState<string>('');
  const [searchResult, setSearchResult] = useState<ISearchResult[]>([]);

  const [isCustomProductName, isSetCustomProductName] = useState(false);

  const { isRefresh } = useTypedSelector((state) => state.refresh);

  const crossBtn = useRef<HTMLImageElement>(null);

  const customProductNameHandler = () => {
    isSetCustomProductName(true);
  };

  const amountHandler = (value: string | undefined) => {
    const updatedState = products.map((item: IProduct) => {
      if (item.id === product.id) {
        return { ...item, amount: value };
      }
      return item;
    });
    setProducts(updatedState);
  };

  const typeHandler = (value: string) => {
    const updatedState = products.map((item: IProduct) => {
      if (item.id === product.id) {
        return { ...item, type: value };
      }
      return item;
    });
    setProducts(updatedState);
  };

  const codeHandler = (value: string) => {
    const updatedState = products.map((item: IProduct) => {
      if (item.id === product.id) {
        return { ...item, code: value };
      }
      return item;
    });
    setProducts(updatedState);
  };

  const clearInput = () => {
    if (product.name.length > 0) {
      const updatedState = products.map((item: IProduct) => {
        if (item.id === product.id) {
          return { ...item, name: '' };
        }
        return item;
      });
      setProducts(updatedState);
    }
  };

  const handleClickOutside = (event: any) => {
    if (crossBtn.current && crossBtn.current.contains(event.target)) {
      clearInput();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const productNameItemHandler = (productName: string) => {
    setName(productName);

    const updatedState = products.map((item: IProduct) => {
      if (item.id === product.id) {
        return { ...item, name: productName };
      }
      return item;
    });
    setProducts(updatedState);
  };

  const debounceName = useDebounce(name, 500);

  useEffect(() => {
    const token: string | null | undefined = getCookie('Authentication');
    if (debounceName?.length > 0 && debounceName?.length < 50) {
      usersApi.search(token, name).then((r) => setSearchResult(r.data.items));
    }
  }, [debounceName, isRefresh]);

  return (
    <div className={s.product} key={product.id}>
      <div className={s.input_bottom}>
        <CustomAutoComplete
          autoFocus={product.id !== 1}
          allowClear
          className={s.search_input_autocomplete}
          options={name !== ''
            ? searchResult.map((el, index) => ({
              label: el.name,
              value: el.name,
              key: index + 1,
            })).concat([{ label: `Добавить ${name}`, value: name, key: 0 }])
            : searchResult.map((el, index) => ({
              value: el.name,
              key: index + 1,
            }))}
          value={product.name}
          onSelect={(e: string) => {
            if (!searchResult.some((res) => res.name === e)) {
              customProductNameHandler();
            } else {
              productNameItemHandler(e);
            }
          }}
          hint={product.name}
          onSearch={productNameItemHandler}
        >
          <Input
            type="text"
            value={product.name}
            required
          />
        </CustomAutoComplete>
      </div>
      <div className={s.input_bottom}>
        <div>
          <Input
            type="text"
            disabled={!isCustomProductName}
            value={product.code}
            onChange={(e: ChangeEvent<HTMLInputElement>) => codeHandler(e.target.value)}
          />
        </div>
      </div>
      <div className={s.input_bottom}>
        <div>
          <CurrencyInput
            required
            defaultValue={1}
            allowDecimals={false}
            allowNegativeValue={false}
            disableGroupSeparators
            disableAbbreviations
            value={product.amount}
            onValueChange={(e: string | undefined) => amountHandler(e)}
            pattern="^[1-9][0-9]*$"
            title="Поле не может иметь значений меньше 1"
          />
        </div>

      </div>
      <div className={s.input_bottom}>
        <div>
          <select
            className={s.input_select}
            value={product.type}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => typeHandler(e.target.value)}
          >
            <option value="штука">штука</option>
            <option value="упаковка">упаковка</option>
          </select>
        </div>

      </div>
      {products.length > 1 && (
        <div className={s.delete_button_wrap}>
          <button
            type="button"
            className={s.delete_button}
            onClick={() => deleteProduct(product.id)}
          >
            <span />
          </button>
        </div>
      )}
    </div>
  );
};

const CustomerTemplateProposal: React.FC = () => {
  const { result } = useTypedSelector((state) => state.responseResult);
  const user = useTypedSelector((state) => state.userProfile);
  const { isRefresh } = useTypedSelector((state) => state.refresh);

  console.log(user);

  const [organization, setOrganization] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [, setUserEmail] = useState<string>('');
  const [linkType, setLinkType] = useState<string>('E-mail');
  const [supplierTypes, setSupplierTypes] = useState<string[]>(['Материалы']);
  const [uniqProviderTypes, setUniqProviderTypes] = useState<string[]>(['Материалы']);
  const [uniqProviders, setUniqProviders] = useState<any>({});
  const [exceptionalSuppliers, setExceptionalSuppliers] = useState([]);
  const [region, setRegion] = useState<string>('г. Санкт-Петербург');
  const [deliveryAddress, setDeliveryAddress] = useState<string>('г. Санкт-Петербург');
  const [comment, setComment] = useState<string>('');
  const [fileData, setFileData] = useState<File | null>(null);
  const [goods, setGoods] = useState<IProduct[]>([]);
  const formRef = useRef<HTMLFormElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setButtonIsLoading] = useState(false);
  const [isContactsOpened, setIsContactsOpened] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [customProviderEmails, setCustomProviderEmails] = useState<string[]>([]);

  const params: any = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);

    const token: string | null | undefined = getCookie('Authentication');

    usersApi.getTemplate(token, params.id)
      .then((r) => {
        setOrganization(user.organization || '');
        setName(user.name || '');
        setNumber(user.phone || '');
        setLinkType(r.data.linkType.name);
        setExceptionalSuppliers(r.data.exceptionalSuppliers.map((item: any) => item.user_id.id));

        if (typeof user.region === 'string') {
          setRegion(user.region);
        } else if (typeof user.region === 'object') {
          setRegion(user.region.name || '');
        }

        setDeliveryAddress(user.address || '');

        setComment(r.data.comment);

        setCustomProviderEmails(r.data.customProviderEmails || []);

        const orderGoods = JSON.parse(localStorage.getItem('DENTMP_SHOWCASE_ORDER') || '[]');
        const orderSource = localStorage.getItem('DENTMP_SHOWCASE_ORDER_SOURCE');

        const mappedOrderGoods = orderGoods.map((item: IProduct) => ({
          id: item.id,
          code: item.code,
          name: item.name,
          amount: item.amount,
          type: item.type,
        }));

        let newGoods = mappedOrderGoods;

        // TODO: надо корзину создавать через бэкенд
        if (!orderSource || window.location.pathname !== orderSource) {
          newGoods = r.data.goods
            .map((item: IProductFromBackend) => ({
              ...item,
              id: nanoid(),
              type: item.type.name,
              amount: item.amount,
            }))
            .concat(mappedOrderGoods);

          localStorage.setItem('DENTMP_SHOWCASE_ORDER', JSON.stringify(newGoods));
          localStorage.removeItem('DENTMP_SHOWCASE_ORDER_SOURCE');
        }

        setGoods(newGoods);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, [isRefresh]);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setButtonIsLoading(true);
    const data = new FormData();

    if (fileData !== null) {
      data.append('file', fileData);
    }

    data.append('organization', organization);
    data.append('name', name.trim());
    data.append('phone', number);
    data.append('linkType', linkType);
    for (let i = 0; i < supplierTypes.length; i += 1) {
      data.append(`supplierTypes[${i}]`, supplierTypes[i]);
    }
    data.append('region', region);
    data.append('deliveryAddress', deliveryAddress);
    data.append('comment', comment);

    for (let i = 0; i < goods.length; i += 1) {
      data.append(`goods[${i}][name]`, goods[i].name);
      data.append(`goods[${i}][amount]`, goods[i].amount);
      data.append(`goods[${i}][type]`, goods[i].type);
      if (goods[i]?.code !== null && goods[i]?.code.length > 0) data.append(`goods[${i}][code]`, goods[i]?.code);
    }

    if (exceptionalSuppliers.length > 0) {
      for (let i = 0; i < exceptionalSuppliers?.length; i += 1) {
        data.append(`exceptionalSuppliers[${i}][userId]`, exceptionalSuppliers[i]);
      }
    }

    if (customProviderEmails.length > 0) {
      for (let i = 0; i < customProviderEmails.length; i += 1) {
        data.append(`customProviderEmails[${i}]`, customProviderEmails[i]);
      }
    }

    const token: string | null | undefined = getCookie('Authentication');

    const createNewProposalResponse = await usersApi.createNewProposal(token, data);
    dispatch({
      type: 'GET_RESULT',
      payload: {
        result: createNewProposalResponse.data.result,
        msg: createNewProposalResponse.data.msg,
        orderId: createNewProposalResponse.data.orderId,
      },
    });

    setButtonIsLoading(false);

    localStorage.removeItem('DENTMP_SHOWCASE_ORDER');
    localStorage.removeItem('DENTMP_SHOWCASE_ORDER_SOURCE');

    if (!createNewProposalResponse.data.result) {
      message.error('Ошибка создания заказа');
    }
  }

  const addProduct = useCallback(() => {
    const newGoods = [...goods, {
      name: '',
      code: '',
      amount: '1',
      id: nanoid(),
      type: 'штука',
    }];

    setGoods(newGoods);

    localStorage.setItem('DENTMP_SHOWCASE_ORDER', JSON.stringify(newGoods));
  }, [goods]);

  const deleteProduct = useCallback((id: number | string) => {
    const newGoods = goods.filter((product: IProduct) => product.id !== id);

    setGoods(newGoods);

    localStorage.setItem('DENTMP_SHOWCASE_ORDER', JSON.stringify(newGoods));
  }, [goods]);

  useEffect(() => {
    const token: string | null | undefined = getCookie('Authentication');
    usersApi.getUniqProviders(token, supplierTypes).then((r) => {
      setUniqProviders(r.data);
    });
  }, [uniqProviderTypes, isRefresh]);

  const setSupplierHandler = useCallback((e: string[]) => {
    for (let i = 0; i < uniqProviders.items?.length; i += 1) {
      if (uniqProviderTypes !== uniqProviders.items[i].providerTypes.name) {
        setExceptionalSuppliers([]);
      }
    }

    if (e.length > 0) {
      setSupplierTypes(e);
      setUniqProviderTypes(e);
    } else {
      setSupplierTypes(['Материалы']);
      setUniqProviderTypes(['Материалы']);
    }
  }, [uniqProviders, uniqProviderTypes]);

  const handleChange = useCallback((value: any) => {
    setExceptionalSuppliers(value);
  }, []);

  const organizationHandler = useCallback((value: string) => {
    setOrganization(value);
  }, []);

  const nameHandler = useCallback((value: string) => {
    setName(value);
  }, []);

  const phoneHandler = useCallback((value: string) => {
    setNumber(value);
  }, []);

  const emailHandler = useCallback((value: string) => {
    setUserEmail(value);
  }, []);

  const linkTypeHandler = useCallback((value: string) => {
    setLinkType(value);
  }, []);

  const handleImportBase = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const [file] = Array.from(event.target.files || []);

      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        await usersApi.importBaseFromFile(formData);

        message.success('База импортирована успешно!');
      }
    } catch (e) {
      console.warn(e);

      message.error('Произошла ошибка при импорте базы');
    }
  };

  const handleImportGoods = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      setIsImporting(true);

      const [file] = Array.from(event.target.files || []);

      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        const { data } = await usersApi.importGoodsFromFile(formData);

        const importedGoods = data.goods.map((good: IProduct) => ({
          id: nanoid(),
          code: '',
          name: good.name,
          amount: good.amount,
          type: good.type,
        }));

        setGoods(importedGoods);

        localStorage.setItem('DENTMP_SHOWCASE_ORDER', JSON.stringify(importedGoods));
      }
    } catch (e) {
      console.warn(e);

      message.error('Произошла ошибка при импорте товаров');
    } finally {
      setIsImporting(false);
    }
  };

  const handleGoodsUpdate = (updatedGoods: IProduct[]) => {
    setGoods(updatedGoods);

    localStorage.setItem('DENTMP_SHOWCASE_ORDER', JSON.stringify(updatedGoods));
  };

  const exportXls = async () => {
    try {
      setIsExporting(true);

      const response = await usersApi.exportGoodsXls({
        goods: goods.map(({ id, ...good }) => ({
          ...good,
          amount: Number(good.amount),
        })),
      });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
      link.download = `proposal_${new Date().getTime()}.xlsx`;
      link.click();

      message.success('Экспорт заявки произошел успешно!');
    } catch (e) {
      console.warn(e);

      message.error('Произошла ошибка при экспорте заявки');
    } finally {
      setIsExporting(false);
    }
  };

  const contactsRef = useRef<HTMLDivElement>(null);

  const settingsMenu = [
    {
      id: 1,
      title: (
        <label>
          Загрузить базу
          <input type="file" onChange={handleImportBase} />
        </label>
      ),
      hint: 'Импорт возможен в формате .xls',
      visible: user.type === UserTypes.Customer,
    },
    {
      id: 2,
      title: (
        <label>
          Импорт из файла
          <input type="file" onChange={handleImportGoods} />
        </label>
      ),
      hint: 'Импорт возможен в формате .xls, в форме заявки отобразятся наименования товаров',
    },
    {
      id: 3,
      title: (
        <span onClick={exportXls}>Экспорт заявки в Excel</span>
      ),
    },
  ];

  const handleClick = () => {
    const invalidElements = formRef.current?.querySelectorAll(':invalid') || [];
    const hasOnlyContactsError = Array.from(invalidElements)
      .every((node) => contactsRef.current?.contains(node));

    if (hasOnlyContactsError && !isContactsOpened) {
      setIsContactsOpened(true);
    }
  };

  return (
    <div className={s.page}>
      {isLoading && (
        <LoaderCentered />
      )}
      {!isLoading && (
        <>
          <div className={s.proposal_settings}>
            <span
              className={cx(s.form_title, s.form_title_link)}
              onClick={() => setIsContactsOpened(!isContactsOpened)}
            >
              Контактные данные
              <button type="button" className={s.settings_icon}>
                {!isContactsOpened && (
                  <RightOutlined />
                )}
                {isContactsOpened && (
                  <LeftOutlined />
                )}
              </button>
            </span>
            <div className={s.form_title_container}>
              <span className={cx(s.form_title, s.hide_on_mobile)}>
                Как работает сервис?
              </span>
              <div className={s.form_title_tooltip}>
                <Tooltip
                  title={About}
                  color="#ffffff"
                  placement="bottomLeft"
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            </div>
          </div>
          <form
            className={s.form}
            action="POST"
            id="new-order"
            ref={formRef}
            onSubmit={(e) => handleSubmit(e)}
          >
            <div
              ref={contactsRef}
              className={cx(s.proposal_contacts, isContactsOpened && s.proposal_contacts__active)}
            >
              <CustomerProposalContacts
                organization={organization}
                organizationHandler={organizationHandler}
                name={name}
                nameHandler={nameHandler}
                number={number}
                phoneHandler={phoneHandler}
                emailHandler={emailHandler}
                fileData={fileData}
                setFileData={setFileData}
                supplierTypes={supplierTypes}
                setSupplierHandler={setSupplierHandler}
                exceptionalSuppliers={exceptionalSuppliers}
                handleChange={handleChange}
                uniqProviders={uniqProviders}
                deliveryAddress={deliveryAddress}
                setDeliveryAddress={setDeliveryAddress}
                comment={comment}
                setComment={setComment}
                region={region}
                setRegion={setRegion}
                linkTypeHandler={linkTypeHandler}
                customProviderEmails={customProviderEmails}
                setCustomProviderEmails={setCustomProviderEmails}
              />
            </div>
            <div className={cx(s.bottom, isContactsOpened && s.bottom__small)}>
              <div className={s.bottom_inner}>
                <div className={s.form_header}>
                  <span className={s.form_title}>
                    Заявка на товар
                  </span>
                  <Dropdown
                    arrow
                    overlay={(
                      <Menu>
                        {settingsMenu
                          .filter(({ visible = true }) => visible)
                          .map(({ id, title, hint }) => (
                            <Menu.Item key={id}>
                              <div className={s.proposal_settings_menu_item}>
                                <span className="font-weight-normal">
                                  {title}
                                </span>
                                {hint && (
                                  <div className={s.form_title_tooltip}>
                                    <Tooltip
                                      title={(
                                        <div className={s.tooltip_content}>
                                          {hint}
                                        </div>
                                      )}
                                      color="#ffffff"
                                      placement="bottomLeft"
                                    >
                                      <InfoCircleOutlined />
                                    </Tooltip>
                                  </div>
                                )}
                              </div>
                            </Menu.Item>
                          ))}
                      </Menu>
                    )}
                    placement="bottomLeft"
                    trigger={['click']}
                  >
                    <button type="button" className={s.settings_icon}>
                      <SettingOutlined />
                    </button>
                  </Dropdown>
                  {(isImporting || isExporting) && (
                    <div className={s.importing_loader}>
                      <LoadingButton />
                    </div>
                  )}
                </div>
                <div className={s.bottom_body}>
                  <div className={s.products_title}>
                    <div>Наименование товара</div>
                    <div>Штрихкод</div>
                    <div>Кол-во</div>
                    <div>Ед-измерения</div>
                  </div>
                  {goods.map((el: IProduct) => (
                    <ProductItem
                      key={el.id}
                      product={el}
                      deleteProduct={deleteProduct}
                      setProducts={handleGoodsUpdate}
                      products={goods}
                    />
                  ))}
                </div>
                <div className={s.buttons}>
                  {goods.length <= 150 && (
                    <div className={s.add_button}>
                      <button type="button" onClick={() => addProduct()}>
                        <div className={s.add_button_inner}>
                          <img src={plusImg} alt="+" />
                          <div>Добавить товар</div>
                        </div>
                      </button>
                    </div>
                  )}
                  <NavLink
                    className={s.marketLink}
                    to={routes.market}
                    onMouseDown={() => localStorage.setItem('DENTMP_SHOWCASE_ORDER_SOURCE', window.location.pathname)}
                  >
                    Добавить товар с витрины
                  </NavLink>
                </div>
                <PermissionsGuard permissions={['CREATE_ORDERS']}>
                  <>
                    <div className={s.bottom_footer}>
                      {!result ? (
                        <ConfirmProposal
                          formRef={formRef}
                          loading={isLoadingButton}
                          onClick={handleClick}
                        />
                      ) : <Redirect to={routes.success} />}
                    </div>
                    <div className={s.footer_text}>
                      Нажимая кнопку «Далее», я подтверждаю свою дееспособность,
                      принимаю условия
                      {' '}
                      <NavLink to={routes.agreement}>
                        Пользовательского соглашения
                        {' '}
                      </NavLink>
                      {' '}
                      и подтверждаю свое
                      {' '}
                      <NavLink to={routes.privacyPolicy}>
                        согласие на обработку персональных данных
                        {' '}
                      </NavLink>
                      в соответствии с Федеральным законом № 152-ФЗ «О
                      персональных данных» от 27.06.2006 г.
                    </div>
                  </>
                </PermissionsGuard>
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default CustomerTemplateProposal;
