/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/prefer-default-export */
import React, {
  PropsWithChildren,
} from 'react';
import useTypedSelector from 'src/hooks/useTypedSelector';
import { selectUserPermissions } from 'src/store/user-permissions/selectors';
import _intersection from 'lodash/intersection';

interface PermissionsGuardProps {
  permissions: string[];
}

export const PermissionsGuard = (props: PropsWithChildren<PermissionsGuardProps>) => {
  const { children, permissions } = props;

  const userPermissions = useTypedSelector(selectUserPermissions);

  if (_intersection(userPermissions, permissions).length === 0) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
};
