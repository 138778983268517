/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import { Radio } from 'antd';
import { NavLink } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';

import Accordion from 'src/components/shared-components/Accordion';

import routes from 'src/routes';

import useTypedSelector from 'src/hooks/useTypedSelector';
import { selectIsProviderType } from 'src/store/userProfile/selectors';

import s from './index.module.css';

const Filters = [
  {
    id: 1,
    title: 'Все категории',
    url: routes.market,
  },
  {
    id: 2,
    title: 'Материалы',
    url: `${routes.market}?supplierType=Материалы`,
  },
  {
    id: 4,
    title: 'Имплантология',
    url: `${routes.market}?supplierType=Имплантология`,
  },
  {
    id: 6,
    title: 'Инструменты',
    url: `${routes.market}?supplierType=Инструменты`,
  },
  {
    id: 7,
    title: 'Ортодонтия',
    url: `${routes.market}?supplierType=Ортодонтия`,
  },
  {
    id: 8,
    title: 'Хоз. товары',
    url: `${routes.market}?supplierType=Хоз. товары`,
  },
  {
    id: 9,
    title: 'Расходники',
    url: `${routes.market}?supplierType=Расходники`,
  },
  {
    id: 10,
    title: 'Гигиена',
    url: `${routes.market}?supplierType=Гигиена`,
  },
];

const Sort = {
  Without: 'WITHOUT',
  DateDesc: 'DATE_DESC',
  DateAsc: 'DATE_ASC',
  NameDesc: 'NAME_DESC',
  NameAsc: 'NAME_ASC',
};

const MarketFilters = () => {
  const isProviderType = useTypedSelector(selectIsProviderType);

  const [query, setQuery] = useQueryParams({
    sort: StringParam,
    supplierType: StringParam,
    search: StringParam,
  });

  const {
    sort = Sort.Without,
    supplierType,
    search,
  } = query;

  return (
    <aside className={s.pageFilter}>
      <ul className={s.filters}>
        {Filters.map((filter) => (
          <li className={s.filter} key={filter.id}>
            <NavLink
              className={s.filterLink}
              // eslint-disable-next-line @typescript-eslint/no-shadow
              isActive={(_, { pathname, search }) => {
              // eslint-disable-next-line @typescript-eslint/no-shadow
                const query = new URLSearchParams(decodeURI(search));
                // eslint-disable-next-line @typescript-eslint/no-shadow
                const supplierType = query.get('supplierType') || '';

                return (
                  `${pathname}${supplierType ? `?supplierType=${supplierType}` : ''}` === filter.url
                );
              }}
              activeClassName={s.filterLinkActive}
              to={filter.url}
            >
              {filter.title}
            </NavLink>
          </li>
        ))}
      </ul>
      {!isProviderType && (
        <NavLink
          className={s.newOrderLink}
          to={localStorage.getItem('DENTMP_SHOWCASE_ORDER_SOURCE') || routes.new}
        >
          Перейти к заказу
        </NavLink>
      )}
      <hr className={s.divider} />
      {(supplierType || search) && (
        <>
          <span className={s.pageFilterTitle}>
            Фильтры
          </span>
          <Accordion title="Сортировка:">
            <div className={s.pageFilterList}>
              <Radio.Group
                className={s.pageFilterRadios}
                onChange={({ target }) => setQuery({ sort: target.value })}
                value={sort}
              >
                <Radio className={s.pageFilterRadio} value={Sort.Without}>
                  Без сортировки
                </Radio>
                <Radio className={s.pageFilterRadio} value={Sort.DateDesc}>
                  Новые сначала
                </Radio>
                <Radio className={s.pageFilterRadio} value={Sort.DateAsc}>
                  Старые сначала
                </Radio>
                <Radio className={s.pageFilterRadio} value={Sort.NameDesc}>
                  По названию: А - Я
                </Radio>
                <Radio className={s.pageFilterRadio} value={Sort.NameAsc}>
                  По названию: Я - А
                </Radio>
              </Radio.Group>
            </div>
          </Accordion>
        </>
      )}
    </aside>
  );
};

export default MarketFilters;
