import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { IMarketGood } from 'src/constants/enums';
import useTypedSelector from 'src/hooks/useTypedSelector';
import routes from 'src/routes';
import { selectIsProviderType } from 'src/store/userProfile/selectors';

import s from './index.module.css';

interface IMarketGoodsListProps {
  items: IMarketGood[]
}

const MarketGoodsList: FC<IMarketGoodsListProps> = (props) => {
  const { items } = props;

  const isProviderType = useTypedSelector(selectIsProviderType);

  if (!items.length) {
    return null;
  }

  return (
    <ul className={s.goodsList}>
      {items.map(({
        code,
        id,
        files,
        name,
      }) => (
        <li key={id} className={s.good}>
          {files.length > 0 && (
            <img
              className={s.goodImage}
              src={files[0].path}
              alt={name}
            />
          )}
          {files.length === 0 && (
            <div className={s.goodImageTemplate} />
          )}
          <p className={s.goodName} title={name}>
            <NavLink
              to={{
                pathname: `${routes.market}/${id}`,
                state: {
                  from: window.location.href,
                },
              }}
            >
              {name}
            </NavLink>
          </p>
          <span className={s.goodCode}>
            Артикул:
            {' '}
            {code}
          </span>
          {!isProviderType && (
            <NavLink
              className={s.addGoodToOrderButton}
              to={{
                pathname: `${routes.market}/${id}`,
                state: {
                  from: window.location.href,
                },
              }}
            >
              Добавить к заказу
            </NavLink>
          )}
        </li>
      ))}
    </ul>
  );
};

export default MarketGoodsList;
