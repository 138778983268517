/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import Agent from 'agentkeepalive';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { stringify } from 'query-string';
import routes from 'src/routes';
import { ProposalStatuses } from 'src/types/proposal';

import {
  GoodStatuses,
  IBackendBranch,
  IBranch,
  IBranchCredentials,
  IEmployee,
  IEmployeeCredentials,
  SupplierTypes,
} from 'src/constants/enums';

import { getUserData } from 'src/store/userProfile/actions';
import { IProductToBackend } from 'src/pages/CustomerProposal/types';
import { store } from '../store';

const { REACT_APP_BACKEND_URL, REACT_APP_AUTH_URL } = process.env;

const baseApi = axios.create({
  baseURL: REACT_APP_BACKEND_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

baseApi.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';

  return config;
});

const auth = axios.create({
  baseURL: REACT_APP_AUTH_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

auth.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';

  return config;
});

const debug = axios.create({
  baseURL: REACT_APP_BACKEND_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  httpAgent: new Agent({
    keepAlive: true,
  }),
});

debug.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';

  return config;
});

export const authApi = {
  reg(
    fio: string,
    email: string,
    password: string,
    userType: string,
    providerType: string[],
    organization: string,
    captchaData: string | null,
  ) {
    if (organization.length !== 0) {
      return auth.post(
        '/registration',
        JSON.stringify({
          name: fio,
          email,
          password,
          userType,
          providerType,
          organization,
          captchaData,
        }),
      );
    }
    return auth.post(
      '/registration',
      JSON.stringify({
        name: fio,
        email,
        password,
        userType,
        providerType,
        captchaData,
      }),
    );
  },
  login({
    email = null,
    password = null,
    branchId = null,
    employeeId = null,
  }: {
    email?: string | null,
    password?: string | null,
    branchId?: number | null,
    employeeId?: number | null,
  }) {
    const body = {
      email: email ? email.trim() : null,
      password: password ? password.trim() : null,
      branchId,
      employeeId,
    };

    const url = branchId || employeeId
      ? '/login/undefined'
      : '/login';

    return auth.post(url, body);
  },
  logOut() {
    return auth.post('/logout');
  },
  verifyEmailAgain(email: string) {
    return auth.get(`/send-confirm-email/?email=${email}`);
  },
  remindPassword(email: string, captchaData: string | null) {
    return auth.post('/send-forget-password', {
      email,
      captchaData,
    });
  },
  setNewPassword(newPassword: string, code: string) {
    return auth.post('/set-forget-password', { newPassword, code });
  },
  confirmEmail(code: string) {
    return auth.get<{ result: boolean }>(`/confirm-email?code=${code}`);
  },
  refresh() {
    return auth.post('/refresh');
  },
  changeEmail(token: string | undefined | null, email: string) {
    return auth.post(
      '/change-email',
      {
        email,
      },
    );
  },

  changePassword(
    currentPassword: string,
    newPassword: string,
  ) {
    return auth.post(
      '/change-password',
      { currentPassword, newPassword, confirmPassword: newPassword },
    );
  },

  loginViaOauth(data: {
    yandexCode?: string;
    googleCode?: string;
  }) {
    return auth.post('/oauth', data);
  },

  registerViaOauth(data: {
    organization: string;
    userType: string;
    providerType: SupplierTypes[];
  }) {
    return auth.post('/oauth/register', data);
  },
};

export const debugApi = {
  log(type: 'soft' | 'hard', data: any) {
    return debug.post(`/front/${type}`, JSON.stringify({
      ...data,
      url: window.location.href + window.location.search,
    }), {
      headers: {
        'Content-Type': 'text/plain',
      },
    });
  },
};

export const usersApi = {
  getUserData() {
    return baseApi.get('/users/profile');
  },

  getPermissions() {
    return baseApi.get('/users/permissions');
  },

  getCompareData(token: string | null | undefined, id: any) {
    return baseApi.get(`/orders/${id}/compare-proposal`);
  },

  getProposals(
    token: string | null | undefined,
    isActiveProposalsTypeSelected: any,
    paginatorPage: any,
    archivePage: any,
    providerType: any,
    branchId: Number,
    employeeId: Number,
  ) {
    const query = stringify({
      page: isActiveProposalsTypeSelected ? paginatorPage : archivePage,
      category: providerType,
      limit: 10,
      branchId: branchId === -1 ? null : branchId,
      employeeId: employeeId === -1 ? null : employeeId,
    }, {
      skipNull: true,
      skipEmptyString: true,
    });

    return baseApi.get(`/orders/${isActiveProposalsTypeSelected ? 'active' : 'archive'}?${query}`);
  },

  closeCart(token: string | null | undefined, id: any, data: any) {
    return baseApi.post(`/orders/${id}/close`, data);
  },
  updateOrdersGoods(token: string | null | undefined, id: any, data: any) {
    return baseApi.patch(`/orders/${id}`, data);
  },
  deleteItemFromCart(
    token: string | null | undefined,
    id: number | string,
    itemId: number | string,
    cartItemId: number | string,
  ) {
    return baseApi.delete(`/carts/${id}:/${itemId}:/${cartItemId}`);
  },

  addProductToCartPost(token: string | null | undefined, id: any, goods: any) {
    return baseApi.post(`/carts/${id}`, { goods });
  },

  addProductToCartPatch(token: string | null | undefined, id: any, goods: any) {
    return baseApi.patch(`/carts/${id}`, { goods });
  },

  getCartData(token: string | null | undefined, id: any) {
    return baseApi.get(`/carts/${id}`);
  },

  getTemplate(token: string | null | undefined, orderId: any) {
    return baseApi.get(`/orders/${orderId}/data`, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  search(token: string | null | undefined, name: string) {
    return baseApi.get(`/goods/search?search=${name}&page=${1}&limit=${50}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  createNewProposal(token: string | null | undefined, data: FormData) {
    return baseApi.post('/orders/new', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  createNewOffer(token: string | null | undefined, data: FormData, id: any) {
    return baseApi.post(`/orders/${id}/new-offer`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  getProviderProposal(token: string | null | undefined, id: any) {
    return baseApi.get(`/orders/${id}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  getProviderProposalStatus(token: string | null | undefined, id: any) {
    return baseApi.get(`/orders/${id}/get-offer-status`);
  },

  setProviderProposalStatus(token: string | null | undefined, id: string) {
    return baseApi.post(`/orders/${id}/set-offer-status`, { status: ProposalStatuses.PROCESSING });
  },

  checkFile(token: string | null | undefined, data: FormData) {
    return baseApi.post('/orders/check-file-offer', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  addProviderType(token: string | null | undefined, value: any) {
    return baseApi.post('/users/add-provider-type', { providerType: value });
  },

  deleteProviderType(token: string | null | undefined, value: any) {
    return baseApi.post('/users/delete-provider-type', { providerType: value });
  },

  getTemplateData() {
    return baseApi.get('/orders/template');
  },

  importGoodsFromFile(data: FormData) {
    return baseApi.post('/orders/upload-goods', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  getUniqProviders(token: string | null | undefined, uniqProviderType: any) {
    return baseApi.post('/users/providers-type', {
      type: uniqProviderType,
      page: 1,
      limit: 100,
    });
  },

  createTemplateData(token: string | null | undefined, data: any) {
    return baseApi.post('/orders/template', data);
  },

  sendCompareOffer(token: string | null | undefined, id: any, data: any) {
    return baseApi.post(`/carts/${id}/send-offers`, data);
  },

  updateData(token: string | null | undefined, updatedData: any) {
    return baseApi.patch('/users/update-profile', updatedData);
  },

  createBranch(data: Omit<IBackendBranch, 'id'>) {
    return baseApi.post('/users/branch', data);
  },

  createEmployee(data: Omit<IEmployee, 'id'>) {
    return baseApi.post('/users/employee', data);
  },

  updateBranch(data: IBackendBranch) {
    const {
      id,
      ...body
    } = data;

    return baseApi.put(`/users/branch/${data.id}`, body);
  },

  updateEmployee(data: IEmployee) {
    const {
      id,
      ...body
    } = data;

    return baseApi.put(`/users/employee/${data.id}`, body);
  },

  deleteBranch(data: Pick<IBranch, 'id'>) {
    return baseApi.delete(`/users/branch/${data.id}`);
  },

  deleteEmployee(data: Pick<IEmployee, 'id'>) {
    return baseApi.delete(`/users/employee/${data.id}`);
  },

  saveBranchCredentials(data: IBranchCredentials) {
    const body = {
      login: data.login.trim(),
      password: data.password.trim(),
    };

    return baseApi.post('/users/branch/credentials', body);
  },

  saveEmployeeCredentials(data: IEmployeeCredentials) {
    const body = {
      login: data.login.trim(),
      password: data.password.trim(),
    };

    return baseApi.post('/users/employee/credentials', body);
  },

  importBaseFromFile(data: FormData) {
    return baseApi.post('/users/upload-goods-base', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  getMarket(data: {
    page: number,
    limit: number,
    supplierType?: string | null,
    sort?: string | null,
    filter?: string | null,
  }) {
    const query = stringify({
      page: String(data.page),
      limit: String(data.limit),
      supplierType: data.supplierType,
      sort: data.sort,
      filter: data.filter,
    }, {
      skipNull: true,
      skipEmptyString: true,
    });

    return baseApi.get(`/showcase?${query}`);
  },

  getMarketGood(data: {
    id: number;
  }) {
    return baseApi.get(`/showcase/${data.id}`);
  },

  getProviderMarket(data: {
    page: number;
    limit: number;
    filter: string;
  }) {
    const query = stringify({
      page: String(data.page),
      limit: String(data.limit),
      filter: data.filter,
    }, {
      skipNull: true,
      skipEmptyString: true,
    });

    return baseApi.get(`/showcase/provider?${query}`);
  },

  createMarketGood(data: FormData) {
    return baseApi.post('/showcase/provider', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  editMarketGood(data: FormData) {
    return baseApi.put('/showcase/provider', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  deleteMarketGood(data: {
    id: number;
  }) {
    return baseApi.delete(`/showcase/provider/${data.id}`);
  },

  getMarketHints(data: {
    search: string;
  }) {
    const query = stringify({
      search: data.search,
    }, {
      skipNull: true,
      skipEmptyString: true,
    });

    return baseApi.get(`/showcase/hints?${query}`);
  },

  deleteMarketHint(data: {
    search: string;
  }) {
    const query = stringify({
      search: data.search,
    }, {
      skipNull: true,
      skipEmptyString: true,
    });

    return baseApi.delete(`/showcase/history?${query}`);
  },

  uploadProviderInvoice(data: {
    id: number;
    file: File,
  }) {
    const formData = new FormData();
    formData.append('file', data.file);

    return baseApi.post(`/orders/${data.id}/upload-provider-invoice`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  compareProvider(data: {
    id: number;
    provider: any;
    goods: any;
  }) {
    return baseApi.post(`/orders/${data.id}/compare-provider`, {
      provider: data.provider,
      goods: data.goods,
    });
  },

  deleteCompareProviderOffer(data: {
    orderId: number;
    offerId: number;
  }) {
    const {
      orderId,
      offerId,
    } = data;

    return baseApi.delete(`/orders/${orderId}/compare-provider/${offerId}`);
  },

  getModeratedMarket(data: {
    page: number;
    limit: number;
  }) {
    const query = stringify({
      page: String(data.page),
      limit: String(data.limit),
    }, {
      skipNull: true,
      skipEmptyString: true,
    });

    return baseApi.get(`/showcase/moderator?${query}`);
  },

  moderateMarketGood(data: {
    id: number;
    status: GoodStatuses;
  }) {
    return baseApi.post('/showcase/moderator', data);
  },

  exportGoodsXls(data: {
    goods: IProductToBackend[]
  }) {
    return baseApi.post('/orders/download-goods', data, {
      responseType: 'blob',
    });
  },

  checkProviderExists(data: {
    email: string;
  }) {
    const {
      email,
    } = data;

    const query = stringify({
      email,
    }, {
      skipNull: true,
      skipEmptyString: true,
    });

    return baseApi.get(`/orders/new/check-provider-exists?${query}`);
  },

  getPurchaseHistory(data: {
    page: number;
    limit: number;
    filter: string;
  }) {
    const query = stringify({
      page: String(data.page),
      limit: String(data.limit),
      filter: data.filter,
    }, {
      skipNull: true,
      skipEmptyString: true,
    });

    return baseApi.get(`/history?${query}`);
  },

  createSubscription(data: {
    returnUrl: string,
  }) {
    return baseApi.post('/subscriptions', {
      returnUrl: data.returnUrl,
    });
  },

  cancelSubscription() {
    return baseApi.delete('/subscriptions');
  },

  getSubscription() {
    return baseApi.get('/subscriptions');
  },
};

const refreshAuthLogic = (failedRequest: any) => (
  authApi.refresh()
    .then((tokenRefreshResponse) => {
      localStorage.setItem('token', tokenRefreshResponse.data.token);

      store.dispatch(getUserData(tokenRefreshResponse.data.token));

      failedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.data.token}`;

      return Promise.resolve();
    }).catch(() => {
      authApi.logOut().then(() => {
        localStorage.clear();

        if (window.location.pathname !== routes.index) {
          window.location.href = routes.index;
        }
      });

      return Promise.resolve();
    })
);

// @ts-ignore
createAuthRefreshInterceptor(baseApi, refreshAuthLogic);
