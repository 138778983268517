import { UserTypes } from 'src/constants/enums';
import { RootState } from '..';

export const getUserProfileBranch = (store: RootState) => store.userProfile;

export const selectUsersBranch = (store: RootState) => {
  const { usersBranch } = getUserProfileBranch(store);

  return usersBranch;
};

export const selectIsProvider = (store: RootState) => {
  const { type } = getUserProfileBranch(store);

  return type === UserTypes.Provider;
};

export const selectIsProviderType = (store: RootState) => {
  const { type } = getUserProfileBranch(store);

  return type === UserTypes.Employee
    || type === UserTypes.Provider;
};

export const selectIsCustomer = (store: RootState) => {
  const { type } = getUserProfileBranch(store);

  return type === UserTypes.Customer;
};

export const selectIsCustomerType = (store: RootState) => {
  const { type } = getUserProfileBranch(store);

  return type === UserTypes.Branch
    || type === UserTypes.Customer;
};

export const selectIsCustomerOrProvider = (store: RootState) => {
  const { type } = getUserProfileBranch(store);

  return type === UserTypes.Provider
    || type === UserTypes.Customer;
};
