import React from 'react';
import { NavLink } from 'react-router-dom';
import routes from 'src/routes';

import { InfoCircleOutlined } from '@ant-design/icons';
import useTypedSelector from 'src/hooks/useTypedSelector';
import { selectSubscription } from 'src/store/subscription/selectors';
import s from './index.module.css';

const SubscriptionNotification = () => {
  const subscription = useTypedSelector(selectSubscription);

  if (subscription == null) {
    return (
      <div className={s.notification}>
        <InfoCircleOutlined style={{ color: '#CA4C32' }} />
        <span>
          Закончилось использование полного функционала сервиса,
          {' '}
          <NavLink className={s.notificationLink} to={routes.subscription.index}>подробнее</NavLink>
        </span>
      </div>
    );
  }

  const rtf = new Intl.RelativeTimeFormat('ru');
  const subscriptionUntil = new Date(subscription.nextPaymentDate).valueOf();
  const diffInDays = Math.ceil((subscriptionUntil - Date.now()) / 1000 / 60 / 60 / 24);

  return (
    <div className={s.notification}>
      <InfoCircleOutlined style={{ color: '#4EA584' }} />
      <span>
        {`${subscription.type === 'demo' ? 'Демонстрационный период' : 'Подписка'} закончится ${rtf.format(diffInDays, 'day')},`}
        {' '}
        <NavLink className={s.notificationLink} to={routes.subscription.index}>подробнее</NavLink>
      </span>
    </div>
  );
};

export default SubscriptionNotification;
