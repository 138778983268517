import {
  applyMiddleware,
  createStore,
  combineReducers,
  Action,
} from 'redux';

import thunk, { ThunkAction } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import popUpReducer from './reducers/popUpReducer';
import authReducer from './auth/reducer';
import userProfileReducer from './userProfile/reducer';
import itemsReducer from './reducers/itemsReducer';
import cartItemReducer from './reducers/cartItemsReducer';
import responseResultReducer from './reducers/responseResultReducer';
import refreshReducer from './reducers/refreshReducer';
import branchReducer from './branch/reducer';
import branchesReducer from './branches/reducer';
import employeeReducer from './employee/reducer';
import employeesReducer from './employees/reducer';
import subscriptionReducer from './subscription/reducer';
import userPermissionsReducer from './user-permissions/reducer';

const rootReducer = combineReducers({
  popUps: popUpReducer,
  auth: authReducer,
  userProfile: userProfileReducer,
  items: itemsReducer,
  cartItems: cartItemReducer,
  responseResult: responseResultReducer,
  refresh: refreshReducer,
  branch: branchReducer,
  branches: branchesReducer,
  employee: employeeReducer,
  employees: employeesReducer,
  subscription: subscriptionReducer,
  userPermissions: userPermissionsReducer,
});

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk)),
);

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<T extends Action> = ThunkAction<void, RootState, unknown, T>;
