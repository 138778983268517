export enum ActionsTypes {
  GET_SUBSCRIPTION = 'GET_SUBSCRIPTION',
  SET_SUBSCRIPTION = 'SET_SUBSCRIPTION',
}

const SubscriptionType = {
  paid: 'paid',
  demo: 'demo',
} as const;

const SubscriptionStatus = {
  activated: 'activated',
  canceled: 'canceled',
  deactivated: 'deactivated',
} as const;

export type ISubscription = {
  type: typeof SubscriptionType.demo;
  nextPaymentDate: string;
} | {
  type: typeof SubscriptionType.paid;
  status: keyof typeof SubscriptionStatus;
  nextPaymentDate: string;
  amount: number;
};

export type SubscriptionState = ISubscription | null;

export interface GetSubscription {
  type: ActionsTypes.GET_SUBSCRIPTION;
}

export interface SetSubscription {
  type: ActionsTypes.SET_SUBSCRIPTION;
  payload?: ISubscription
}

// далее, если нужно добавить другие типы экшенов - ставим | и добавляем */
export type SubscriptionActions = GetSubscription | SetSubscription;
