export enum ActionsTypes {
  SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS',
}

export type IUserPermissions = string[];

export interface ISetUserPermissions {
  type: ActionsTypes.SET_USER_PERMISSIONS;
  payload: IUserPermissions;
}

export type UserPermissionsActions = ISetUserPermissions;

export type UserPermissionsState = string[];
